import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import useLang from "@hooks/useLang";
import useDefaultArticles from "@staticQueries/DefaultArticlesQuery";
import { ArticleCard } from "@molecules";
import { Container, Text, Image, Icon, AnimateIn } from "@atoms";

const BlogPreview = ({
  articles: _articles,
  descriptor,
  heading,
  paddingTop,
  showDivider,
}) => {
  const [activeArticle, setActiveArticle] = useState(0);

  const lang = useLang();
  const defaultArticles = useDefaultArticles()[lang === "en" ? "en" : "es"];
  const articles = [..._articles, ...defaultArticles].slice(0, 3);

  const variants = {
    enter: {
      opacity: 1,
      transition: { type: "tween", duration: 0.3 },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 0.3 },
    },
  };

  return (
    <section
      className={classNames("pb-20 pt-20 md:pb-28 md:pt-28", {
        "border-t-2 border-black": showDivider,
      })}
    >
      <Container>
        <div className="flex flex-wrap gap-6 sm:gap-20">
          <AnimateIn
            preset="fadeUpFast"
            className="w-full sm:w-1/3 md:w-2/5 md:whitespace-pre-line"
          >
            <Text variant="h2" className="md:max-w-[12ch]">
              {heading}
            </Text>
          </AnimateIn>
          <AnimateIn preset="fadeUpFast" className="flex-1">
            <Text
              className={classNames(
                "block text-2xl leading-tight md:text-3xl",
                {
                  "sm:pt-[.55rem] md:pt-4": heading,
                }
              )}
            >
              {descriptor}
            </Text>
          </AnimateIn>
        </div>
        {/* article listing */}
        <AnimateIn preset="fadeUpFast" delay={0.2}>
          <div className="mt-12 flex flex-wrap gap-8 md:mt-20">
            <div className="relative aspect-video w-full md:w-3/5">
              {articles?.map((article, i) => {
                return (
                  <m.div
                    key={article.uid}
                    animate={activeArticle === i ? "enter" : "exit"}
                    variants={variants}
                    className={classNames("absolute inset-0", {
                      "bg-gray": !article.image,
                    })}
                  >
                    {article.image && (
                      <Image
                        image={article.image}
                        eager
                        aspectRatio={[16, 9]}
                      />
                    )}
                    {!article.image && (
                      <div className="flex h-full items-center justify-center">
                        <Icon
                          name="logoIcon"
                          className="h-auto w-1/4 text-green"
                        />
                      </div>
                    )}
                  </m.div>
                );
              })}
            </div>
            <div className="flex flex-1 flex-col space-y-8">
              {articles?.map((article, i) => (
                <m.div
                  key={article.uid}
                  onHoverStart={() => setActiveArticle(i)}
                  className="border-b-2 border-black pb-8 last:border-0 last:pb-0"
                >
                  <ArticleCard
                    idx={i}
                    model={[activeArticle, setActiveArticle]}
                    {...article}
                  />
                </m.div>
              ))}
            </div>
          </div>
        </AnimateIn>
      </Container>
    </section>
  );
};

BlogPreview.defaultProps = {};

export default BlogPreview;
