import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query DefaultArticlesQuery {
    craft {
      en: entries(section: "articles", limit: 3) {
        ... on Craft_articles_article_Entry {
          uid
          uri
          title
          # authors
          date: postDate
          image: image0 {
            ...ImageFragment
          }
        }
      }
      es: entries(
        section: "articles"
        site: "artsCouncilSccSpanishEs"
        limit: 3
      ) {
        ... on Craft_articles_article_Entry {
          uid
          uri
          title
          # authors
          date: postDate
          image: image0 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = entries => {
  const articles = entries?.map(article => {
    return {
      ...article,
      image: resolveImage(article.image),
    };
  });

  return articles;
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useData;
